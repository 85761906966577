export const objectColors = [
  {
    object: "function",
    color: "yellow",
  },
  {
    object: "const",
    color: "blue",
  },
  {
    object: "(",
    color: "yellow",
  },

  {
    object: ")",
    color: "yellow",
  },

  {
    object: "{",
    color: "yellow",
  },
  {
    object: "[",
    color: "yellow",
  },
  {
    object: "]",
    color: "yellow",
  },
  {
    object: "}",
    color: "yellow",
  },
  {
    object: "=>",
    color: "blue",
  },
  {
    object: "return",
    color: "purple",
  },
  {
    object: "export",
    color: "purple",
  },
  {
    object: "default",
    color: "purple",
  },
  {
    object: "break",
    color: "purple",
  },
  {
    object: "case",
    color: "yellow",
  },
  {
    object: "while",
    color: "purple",
  },
  {
    object: "props.",
    color: "blue",
  },
  {
    object: "state",
    color: "blue",
  },
  {
    object: "process.",
    color: "blue",
  },
  {
    object: "res.",
    color: "blue",
  },
  {
    object: "req.",
    color: "blue",
  },
  {
    object: "document",
    color: "purple",
  },
  {
    object: ".bind",
    color: "purple",
  },
  {
    object: "random",
    color: "orange",
  },
  {
    object: "querySelector",
    color: "orange",
  },
  {
    object: "getElementById",
    color: "orange",
  },
  {
    object: "getElementsByTagName",
    color: "orange",
  },
  {
    object: "getElementsByClassName",
    color: "orange",
  },
  {
    object: "getAttribute",
    color: "orange",
  },
  {
    object: "querySelectorAll",
    color: "orange",
  },
  {
    object: ".contains",
    color: "orange",
  },
  {
    object: ".add",
    color: "orange",
  },
  {
    object: ".toggle",
    color: "orange",
  },
  {
    object: ".remove",
    color: "orange",
  },
  {
    object: ".className",
    color: "orange",
  },
  {
    object: ".id",
    color: "orange",
  },
  {
    object: "floor",
    color: "orange",
  },
  {
    object: "clearInterval",
    color: "orange",
  },
  {
    object: "setInterval",
    color: "orange",
  },
  {
    object: ".getHours",
    color: "orange",
  },
  {
    object: ".getSeconds",
    color: "orange",
  },
  {
    object: ".getMinutes",
    color: "orange",
  },
  {
    object: ".getTime",
    color: "orange",
  },
  {
    object: "this",
    color: "blue",
  },
  {
    object: "event.",
    color: "blue",
  },
  {
    object: "static",
    color: "blue",
  },
  {
    object: "let",
    color: "blue",
  },
  {
    object: "var",
    color: "blue",
  },
  {
    object: "setInterval",
    color: "orange",
  },
  {
    object: "new Date",
    color: "purple",
  },
  {
    object: "super",
    color: "purple",
  },

  {
    object: "Math",
    color: "blue",
  },
  {
    object: "console.log",
    color: "blue",
  },
  {
    object: ".setAttribute",
    color: "orange",
  },
  {
    object: "switch",
    color: "purple",
  },
  {
    object: "false",
    color: "blue",
  },
  {
    object: "true",
    color: "blue",
  },
  {
    object: "useEffect",
    color: "yellow",
  },
  {
    object: "useState",
    color: "yellow",
  },
  {
    object: "window",
    color: "blue",
  },
  {
    object: "!",
    color: "red",
  },
  {
    object: ";",
    color: "yellow",
  },
  {
    object: ":",
    color: "yellow",
  },
  {
    object: "children",
    color: "orange",
  },
]
