import React from "react"
import Layout from "../src/components/layout"
import { dataPages } from "../data/dataPages"
import { objectColors } from "./objectColors"
import { ConvertCodeSpacingAndColorLines } from "./functions"

const PageTemplate = props => {
  const pageContent = dataPages.filter(
    item => item.page === props.pageContext.slug
  )[0]
  const menuLeftContent = pageContent.content.map(item => {
    return item.title
  })

  return (
    <Layout
      title={pageContent.title}
      menuLeft={menuLeftContent}
      pathname={props.location.pathname}
    >
      <ConvertCodeSpacingAndColorLines
        pageContent={pageContent}
        objectColors={objectColors}
      />
    </Layout>
  )
}
export default PageTemplate
