import React from "react"
import styled from "styled-components"

const ConvertToSpanElements = text => {
  const htmlElements = []
  let beforeCleanArr = []
  const arrText = text.split("**")
  let arrToPush = []
  const spansElements = arrText.map((item, index) => {
    const ifSpanArrOpen = item.includes(`<span`)
    const ifSpanArrClose = item.includes(`</span`)
    if (arrToPush.length === 1) {
      arrToPush.push(item)
      return ""
    } else {
      if (ifSpanArrOpen || ifSpanArrClose) {
        if (ifSpanArrOpen) {
          const textColor = item.slice(
            "<span className=".length,
            item.length - 1
          )
          arrToPush.push("" + textColor + "")
          return ""
        } else if (ifSpanArrClose) {
          beforeCleanArr = [...arrToPush]
          arrToPush = []
          return (
            <span id={index} className={beforeCleanArr[0]}>
              {beforeCleanArr[1]}
            </span>
          )
        }
      } else {
        return item
      }
    }
    return ""
  })
  spansElements.forEach((item, index) => {
    if (item.type) {
      if (item.type === "span") {
        if (item.props.children) {
          if (item.props.children.length > 0) {
            htmlElements.push(
              React.createElement(
                "span",
                {
                  className: item.props.className,
                  key:
                    Math.floor(
                      Math.random() * index * spansElements.length * 1000
                    ) + item.props.className,
                },
                item.props.children
              )
            )
          }
        }
      }
    } else {
      if (item.length > 0) {
        htmlElements.push(item)
      }
    }
  })
  return htmlElements
}

const ChangeElements = (objectsColors, textItemFrom) => {
  let textItem = textItemFrom
  objectsColors.forEach(item => {
    if (textItem.includes(item.object)) {
      const indexSelected = textItem.indexOf(item.object)
      const textSelected = textItem.slice(
        indexSelected,
        indexSelected + item.object.length
      )
      const textBeforeSelected = textItem.slice(0, indexSelected)
      const textAfterSelected = textItem.slice(
        indexSelected + item.object.length,
        textItem.length
      )
      if (textSelected.length === 1) {
        const textToReplace = `**<span className=${item.color}>**${textSelected}**</span>**`

        const textItemArrToReplace = textItem.split("")
        let textItemReplace = ""

        textItemArrToReplace.forEach(itemReplace => {
          if (itemReplace === textSelected) {
            textItemReplace = textItemReplace + textToReplace
          } else {
            textItemReplace = textItemReplace + itemReplace
          }
        })
        textItem = textItemReplace
      } else {
        textItem = `${textBeforeSelected}**<span className=${item.color}>**${textSelected}**</span>**${textAfterSelected}`
      }
    }
  })
  return textItem
}

const ElementPosition = styled.div`
  padding: 0 5%;
`

const ElementContent = styled.div`
  background-color: #1e1e1e;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;
  border-radius: 5px;
`

const Title = styled.div`
  background-color: #0084d0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px 10px;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
`

const CodeParagraph = styled.div`
  padding: 20px;
  font-size: 0.8rem;
  word-break: break-word;
`

const LineCode = styled.div`
  color: ${props =>
    props.color === "blue"
      ? "#569cca"
      : props.color === "green"
      ? "#4caf50"
      : props.color === "darkGreen"
      ? "green"
      : props.color === "red"
      ? "red"
      : props.color === "yellow"
      ? "yellow"
      : props.color === "orange"
      ? "orange"
      : props.color === "gray"
      ? "gray"
      : props.color === "lightOrange"
      ? "brown"
      : "white"};

  display: ${props => (props.displayInlineBlock ? "inline-block" : "block")};
  padding-left: ${props =>
    props.spaceLeft ? `${props.spaceLeft * 20}px` : "0px"};

  .red {
    color: red;
  }

  .yellow {
    color: yellow;
  }

  .purple {
    color: #aa00ff;
  }

  .orange {
    color: orange;
  }

  .blue {
    color: #2196f3;
  }

  .gray {
    color: gray;
  }
  .lightGreen {
    color: #76ff03;
  }
  .white {
    color: white;
  }
`

export const ConvertCodeSpacingAndColorLines = ({
  pageContent,
  objectColors,
}) => {
  return pageContent.content.map((textItem, textIndex) => {
    const codeText = textItem.code.trim()
    let arrTextCode = codeText.split("\n")
    arrTextCode = arrTextCode.map(item => {
      return item.trim()
    })
    let spaceLeft = 0
    let elementBefore = ""
    const mapCode = arrTextCode.map((item, index) => {
      let displayInlineBlock = false
      let color = "white"
      if (index > 0) {
        let elementAll = ""
        let elementAllAfter = ""
        elementBefore = arrTextCode[index - 1]

        const elementAfter = item
        const ifSeparatorAfter = elementAfter.includes("<")
        if (ifSeparatorAfter > 0) {
          const indexOpenSeparator = elementAfter.indexOf("<")
          const indexCloseSeparatorLong = elementAfter.indexOf("/>")
          const indexCloseSeparatorShort = elementAfter.indexOf(">")
          const ifCloseSeparator = elementAfter.includes("/>")
          const ifOpenAndCloseSeparator = elementAfter.includes("</")
          const indexOpenSeparatorAndClose = elementAfter.indexOf("</")
          let secondElement = ""
          let firstElement = ""
          if (ifOpenAndCloseSeparator) {
            firstElement = elementAfter.slice(
              indexOpenSeparatorAndClose,
              indexOpenSeparatorAndClose + 2
            )
          } else {
            firstElement = elementAfter.slice(
              indexOpenSeparator,
              indexOpenSeparator + 1
            )
          }
          if (ifCloseSeparator) {
            secondElement = elementAfter.slice(
              indexCloseSeparatorLong,
              indexCloseSeparatorLong + 2
            )
          } else {
            secondElement = elementAfter.slice(
              indexCloseSeparatorShort,
              indexCloseSeparatorShort + 1
            )
          }
          elementAllAfter = firstElement + "x" + secondElement
        }

        const ifSeparatorBefore = elementBefore.includes("<")
        if (ifSeparatorBefore > 0) {
          const indexOpenSeparator = elementBefore.indexOf("<")
          const indexCloseSeparatorLong = elementBefore.indexOf("/>")
          const indexCloseSeparatorShort = elementBefore.indexOf(">")
          const ifCloseSeparator = elementBefore.includes("/>")
          const ifOpenAndCloseSeparator = elementBefore.includes("</")
          const indexOpenSeparatorAndClose = elementBefore.indexOf("</")
          let secondElement = ""
          let firstElement = ""
          if (ifOpenAndCloseSeparator) {
            firstElement = elementBefore.slice(
              indexOpenSeparatorAndClose,
              indexOpenSeparatorAndClose + 2
            )
          } else {
            firstElement = elementBefore.slice(
              indexOpenSeparator,
              indexOpenSeparator + 1
            )
          }
          if (ifCloseSeparator) {
            secondElement = elementBefore.slice(
              indexCloseSeparatorLong,
              indexCloseSeparatorLong + 2
            )
          } else {
            secondElement = elementBefore.slice(
              indexCloseSeparatorShort,
              indexCloseSeparatorShort + 1
            )
          }
          elementAll = firstElement + "x" + secondElement
        }

        if (elementAll === "<x>") {
          spaceLeft = spaceLeft + 1
        }

        if (elementAllAfter === "</x>") {
          spaceLeft = spaceLeft - 1
        }
      }
      if (arrTextCode.length > index + 1) {
        if (arrTextCode[index + 1].includes("//")) {
          displayInlineBlock = true
        }
      }

      if (
        elementBefore.includes("(") &&
        elementBefore.includes("{") &&
        elementBefore.includes(")") &&
        elementBefore.includes("}")
      ) {
        const firstElementIndex1 = elementBefore.indexOf("(")
        const firstElementIndex2 = elementBefore.indexOf("{")
        const secondElementIndex1 = elementBefore.indexOf("}")
        const secondElementIndex2 = elementBefore.indexOf(")")
        const selectedElementIndex = elementBefore.lastIndexOf("{")

        if (
          firstElementIndex1 <
          firstElementIndex2 <
          secondElementIndex1 <
          secondElementIndex2 <
          selectedElementIndex
        ) {
          spaceLeft = spaceLeft + 1
        }
      }
      if (elementBefore.includes("{") && !elementBefore.includes("}")) {
        spaceLeft = spaceLeft + 1
      }
      if (item.includes("}") && !item.includes("{")) {
        spaceLeft = spaceLeft - 1
      }

      if (elementBefore.includes("[") && !elementBefore.includes("]")) {
        spaceLeft = spaceLeft + 1
      }
      if (item.includes("]") && !item.includes("[")) {
        spaceLeft = spaceLeft - 1
      }

      if (
        elementBefore.includes("(") &&
        !elementBefore.includes(")") &&
        !elementBefore.includes("())")
      ) {
        spaceLeft = spaceLeft + 1
      }
      if (
        item.includes(")") &&
        !item.includes("(") &&
        !item.includes("])") &&
        !item.includes("()")
      ) {
        spaceLeft = spaceLeft - 1
      }

      if (item.includes("//")) {
        displayInlineBlock = true
        color = "darkGreen"
      }

      if (item.includes("=>")) {
        color = "orange"
      }

      if (item.includes("function")) {
        color = "orange"
      }

      if (
        item.includes("npm") ||
        item.includes("plugin") ||
        item.includes("localhost")
      ) {
        color = "gray"
      }

      if (
        item.includes("background") ||
        item.includes("color") ||
        item.includes("display") ||
        item.includes("position") ||
        item.includes("width") ||
        item.includes("height") ||
        item.includes("margin") ||
        item.includes("padding") ||
        item.includes("box-shadow") ||
        item.includes("font") ||
        item.includes("border") ||
        item.includes("transition") ||
        item.includes("transform") ||
        item.includes("opacity") ||
        item.includes("text-align") ||
        item.includes("cursor") ||
        item.includes("overflow")
      ) {
        color = "blue"
      }

      if (item.includes("export")) {
        color = "yellow"
      }

      if (item.includes("import")) {
        color = "blue"
      }

      if (item.includes("require")) {
        color = "blue"
      }

      if (item.includes("<" || ">")) {
        color = "green"
      }

      let textItem = ChangeElements(objectColors, item)
      const newTextSpan = ConvertToSpanElements(textItem)

      return (
        <LineCode
          color={color}
          spaceLeft={spaceLeft}
          displayInlineBlock={displayInlineBlock}
          key={index + "code"}
        >
          {newTextSpan}
        </LineCode>
      )
    })
    return (
      <ElementPosition id={textItem.title.toLowerCase()} key={textIndex}>
        <ElementContent>
          <Title className="text-uppercase">{textItem.title}</Title>
          <CodeParagraph>{mapCode}</CodeParagraph>
        </ElementContent>
      </ElementPosition>
    )
  })
}
